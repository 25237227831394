<template>
  <div id="Manage">
    <!-- 基础设置 品牌管理-->

    <!-- Dialog 对话框 -->
    <el-dialog :title="dialogFonfig.openType === 'add' ? dialogFonfig.addName : dialogFonfig.editName"
               :visible.sync="dialogFonfig.isShowDialogVisible"
               @click="clickCancelData">
      <CommonForm :form="openForm"
                  :formLabel="openFormLabel"
                  @modifychecked2='getSupplyID'
                  ref="form"></CommonForm>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="clickCancelData">取 消</el-button>
        <el-button type="primary"
                   @click="clickSubmitData(dialogFonfig.openType, openForm)">确 定</el-button>
      </div>
    </el-dialog>
    <!-- Dialog 对话框 审核表单-->
    <el-dialog :title="dialogFonfigAudit.auditName"
               class="dialogAudit"
               :close-on-click-modal="false"
               :visible.sync="dialogFonfigAudit.isShowDialogVisible">
      <CommonForm :form="auditForm"
                  class="dialogAuditForm"
                  :formLabel="auditFormLabel"
                  inline></CommonForm>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="clickCancelData">取 消</el-button>
        <el-button type="danger"
                   @click="clickSubmitAuditData(2, auditForm)">驳 回</el-button>
        <el-button type="success"
                   @click="clickSubmitAuditData(1, auditForm)">通 过</el-button>
      </div>
    </el-dialog>
    <CommonManageMain>
      <template v-slot:header>
        <div>
          <el-button type="primary"
                     size="small"
                     @click="clickAddData"
                     icon="el-icon-circle-plus-outline">添加供应商</el-button>
          <!-- <el-button type="danger" size="small" @click="clickDelData" icon="el-icon-delete">删除</el-button> -->

          <CommonForm :form="searchForm"
                      :formLabel="searchformLabel"
                      class="filtrateInput">
            <el-button type="primary">搜索</el-button>
            <el-button>导出</el-button>
            <el-button type="text">查看已导出列表</el-button>
            <el-button type="text">重置筛选条件</el-button>
          </CommonForm>
        </div>
      </template>
      <!-- 表格内容框 -->
      <template v-slot:content>
        <CommonTable :tableData="tableData"
                     :tableLabel="tableLabel"
                     :tableConfig="tableConfig">
          <el-table-column label="操作"
                           width="180">
            <template slot-scope="scope">
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="审核"
                          placement="top">
                <span @click='clickAuditData(scope.$index, scope.row)'>审核</span>
              </el-tooltip>
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="编辑"
                          placement="top">
                <span @click="clickEditData(scope.$index, scope.row)">编辑</span>
              </el-tooltip>
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="删除"
                          placement="top">
                <span @click='clickDeleteData(scope.$index, scope.row)'>删除</span>
              </el-tooltip>

              <!-- <el-tooltip
                :enterable="false"
                class="item"
                effect="dark"
                content="关联权限"
                placement="top"
              >
                <el-button
                  type="warning"
                  size="mini"
                  icon="el-icon-setting"
                  @click="clickEditRole(scope.row)"
                ></el-button>
              </el-tooltip> -->
            </template>
          </el-table-column>
        </CommonTable>
      </template>
    </CommonManageMain>
  </div>
</template>

<script>
import { getSuppliperList, methSuppliperData, getSuppliperListDetail, AuditSupplier } from 'api/organize.js'
import CommonForm from 'components/CommonForm.vue'
import CommonTable from 'components/CommonTable.vue'
import CommonManageMain from 'components/CommonManageMain.vue'

export default {
  components: {
    CommonForm,
    CommonTable,
    CommonManageMain
  },
  data () {
    return {
      // 对话框 Dialog 基础数据
      dialogFonfig: {
        openType: 'add',
        addName: '添加供应商信息',
        editName: '编辑供应商信息',
        isShowDialogVisible: false
      },
      // 对话框 Dialog 审核供应商
      dialogFonfigAudit: {
        auditName: '待审核',
        isShowDialogVisible: false
      },

      //form表单 审核
      auditForm: {
        id: '',
        name: '',
        status: '',
        remarks: ''
      },
      auditFormLabel: [
        {
          model: 'id',
          label: '供应商ID',
          width: '400px',
          type: 'disabled'
        },
        {
          model: 'name',
          label: '供应商名称',
          width: '400px',
          type: 'disabled'
        },
        {
          model: 'remarks',
          label: '审核理由',
          type: 'textarea',
          width: '600px',
          required: true
        },

      ],

      // form表单 基础数据
      openForm: {
        username: '',
        password: '',
        nickname: '',
        abbreviation: '',
        telephone: '',
        province_id: '',
        area: '',
        city_id: '',
        county_id: '',
        town_id: '',
        address: '',
        license_pic: [],
        entry_date: '',
        name: '',
        phone: '',
        remarks: '',
        type: [],
        goods_category_id: '',
        tour_area_id: '',
        operation_center_id: ''
      },
      openFormLabel: [
        {
          model: 'username',
          label: '账号',
          width: '400px',
        },
        {
          model: 'password',
          label: '密码',
          width: '400px',
        },
        {
          model: 'nickname',
          label: '全称',
          width: '400px',
        },
        {
          model: 'abbreviation',
          label: '简称',
          width: '400px',
        },
        {
          model: 'telephone',
          label: '联系方式',
          width: '400px',
        },
        {
          model: 'area',
          area: {
            area1: 'province_id',
            area2: 'city_id',
            area3: 'county_id',
            area4: 'town_id'
          },
          label: '地址',
          type: 'cascaderArea',
          width: '100%',
          // required: true
        },
        {
          model: 'address',
          label: '详细地址',
          width: '400px',
        },
        {
          model: 'license_pic',
          label: '营业执照图片',
          type: 'uploadList',
          maxSize: 500,
          imgHeight: '1080',
          imgWidth: '808',
        },
        {
          model: 'entry_date',
          type: 'selectTime',
          label: '入驻时间',
          width: '400px',
        },
        {
          model: 'name',
          label: '负责人',
          width: '400px',
        },
        {
          model: 'phone',
          label: '负责人手机',
          width: '400px',
        },
        {
          model: 'remarks',
          label: '备注',
          width: '400px',
        },
        {
          model: 'type',
          type: 'supplyType',
          options: [
            { id: 0, sType: '旅游' },
            { id: 1, sType: '酒店' },
            { id: 2, sType: '民宿' },
            { id: 3, sType: '商品' },
            { id: 4, sType: '门票' },
          ]
        },
        {
          model: 'goods_category_id',
          label: '主要产品种类',
          type: 'select',
          width: '400px',
          options: [
            { value: 0, label: '家居用品' }
          ]
        },
        {
          model: 'tour_area_id',
          label: '主要旅游区域',
          type: 'select',
          width: '400px',
          options: [
            { value: 0, label: '东莞' }
          ]
        },
        {
          model: 'operation_center_id',
          label: '关联运营中心ID',
          width: '400px',
        },
      ],

      // form表单 搜索数据
      searchForm: {
        keyword: ''
      },
      searchformLabel: [
        {
          model: 'scenicName',
          label: '景点名称',
          width: '300px'
        },
        {
          model: 'scenicProvince',
          label: '省份名称',
          width: '300px'
        },
        {
          model: 'scenicCity',
          label: '城市名称',
          width: '300px'
        },
        {
          model: 'scenicArea',
          label: '镇/区',
          width: '300px'
        },
        {
          model: 'scenicState',
          label: '状态',
          type: 'select',
          width: '200px',
          options: [
            // value:'值',label:'标签'
            { value: true, label: '启用' },
            { value: false, label: '禁用' },
          ],
        },
      ],
      // table 表格 基础数据
      tableData: [],
      tableLabel: [
        {
          prop: 'ID',
          label: '供应商ID',
        },
        {
          prop: 'nickname',
          label: '全称'
        },
        {
          prop: 'abbreviation',
          label: '简称'
        },
        {
          prop: 'telephone',
          label: '联系方式'
        },
        {
          prop: 'name',
          label: '负责人'
        },
        {
          prop: 'entry_date',
          label: '创建时间'
        },
        {
          prop: 'type',
          label: '供应类型',
          width: '200px'
        },
        {
          prop: 'status',
          label: '状态',
          type: 'State'
        },
      ],
      tableConfig: {
        isShowLoading: true,
        // 改造状态列显示效果 el-switch

        page: 1,
        total: 30,
        // 加载状态
        loading: false,
        // 显示 选择多行数据
        selection: false
      },


      // 供应商-全部-数据
      supplierAlldataObj: {},

      // id最大值
      MaxId: ''
    }
  },

  mounted () {
    this.getSuppliperList();

  },
  beforeUpdate () {
  },
  watch: {
    supplierAlldataObj: function () {
      this.tableData = this.dataFormatDeal(
        this.supplierAlldataObj.list,
        {
          nickname: 'nickname',
          id: "ID",
          abbreviation: 'abbreviation',
          telephone: 'telephone',
          entry_date: 'entry_date',
          status: 'status',
          type: 'type',
        },
      )
    }
  },

  methods: {
    //处理时间戳
    formatDate (time) {
      var year = time.getFullYear();
      var month = time.getMonth() + 1;
      var date = time.getDate()
      return year + '-' + month + '-' + date
    },
    //获取复选框的ID
    getSupplyID (data) {
      if (data) {
        let arr = []
        data.forEach(item => {
          arr.push(item.supplyItemId)
        })
        for (var i = 0; i < arr.length; i++) {

          arr[i] = JSON.stringify(arr[i]);
        }
        console.log(arr)
        this.openForm.type = arr
        console.log(this.openForm.type)
      }

    },
    // 获取--供应商列表数据
    getSuppliperList () {
      getSuppliperList().then(res => {
        // console.log(res)
        const DATA = res.data
        if (DATA.code == 200) {
          this.$message({
            message: DATA.message,
            type: 'success'
          })
          DATA.data.list.forEach(item => {
            //处理创建时间
            item.entry_date = new Date(item.entry_date * 1000)
            item.entry_date = this.formatDate(item.entry_date)
            //处理供应类型数据
            for (let i = 0; i < item.type.length; i++) {
              if (item.type[i] == '0') {
                item.type[i] = '旅游'
              } else if (item.type[i] == '1') {
                item.type[i] = '酒店'
              } else if (item.type[i] == '2') {
                item.type[i] = '民宿'
              } else if (item.type[i] == '3') {
                item.type[i] = '商品'
              } else if (item.type[i] == '4') {
                item.type[i] = '门票'
              }
            }
            item.type = item.type.join()
          })

          this.supplierAlldataObj = DATA.data
        }
      })


    },

    //添加数据   --添加-编辑 供应商管理
    methSuppliperData (met, data) {
      return new Promise(resolve => {
        methSuppliperData(met, data).then(res => {
          console.log(res)
          const DATA = res.data
          resolve(DATA)
          console.log(DATA)
        })
      })

    },
    //审核酒店、民宿  接口
    AuditSupplier (data) {
      console.log(data)
      return new Promise(resolve => {
        AuditSupplier(data).then((res) => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            resolve(res)
          } else {
            this.$message({
              message: DATA.message,
              type: 'warning'
            })
          }
        })
      })
    },
    // 数据格式处理
    dataFormatDeal (arr, keyMap, copyInKeyMap) {
      // global 全局方法处理

      return this.globalDeepCloneData(arr, keyMap, copyInKeyMap)
    },

    // 获取最大的 id值
    MaxID (arr, keyMap) {
      let maxid = 0
      for (const val of arr) {
        maxid = maxid > val[keyMap] ? maxid : val[keyMap]
      }
      return maxid
    },

    // 添加数据
    clickAddData () {
      this.MaxId = this.MaxID(this.tableData, 'ID')
      this.openForm.id = this.MaxId + 1
      this.dialogFonfig.openType = 'add'
      this.dialogFonfig.isShowDialogVisible = true
      this.getSupplyID()
    },

    // 确定提交数据操作
    clickSubmitData (type, data) {
      let arr2 = []
      let arr3 = []
      arr2.push(data.goods_category_id)
      arr3.push(data.tour_area_id)
      data.entry_date = data.entry_date.toString()
      console.log(data.entry_date)
      for (var i = 0; i < 3; i++) {
        data.entry_date = data.entry_date.substr(0, data.entry_date.length - 1)
      }
      data.entry_date = Number(data.entry_date)
      if (type == 'add') {
        const DATA = {
          username: data.username,
          password: data.password,
          nickname: data.nickname,
          abbreviation: data.abbreviation,
          telephone: data.telephone,
          province_id: data.province_id,
          city_id: data.city_id,
          county_id: data.county_id,
          town_id: 0,
          address: data.address,
          license_pic: data.license_pic,
          entry_date: data.entry_date,
          name: data.name,
          phone: data.phone,
          remarks: data.remarks,
          type: data.type,
          goods_category_id: arr2,
          tour_area_id: arr3,
          operation_center_id: data.operation_center_id,
        }
        console.log(this.openForm)
        this.methSuppliperData('POST', DATA)
        this.getSuppliperList()

      }
      else if (type == 'edit') {
        const DATA = {
          id: data.id,
          username: data.username,
          password: data.password,
          nickname: data.nickname,
          abbreviation: data.abbreviation,
          telephone: data.telephone,
          province_id: data.province_id,
          city_id: data.city_id,
          county_id: data.county_id,
          town_id: 0,
          address: data.address,
          license_pic: data.license_pic,
          entry_date: data.entry_date,
          name: data.name,
          phone: data.phone,
          remarks: data.remarks,
          type: data.type,
          goods_category_id: arr2,
          tour_area_id: arr3,
          operation_center_id: data.operation_center_id,
        }
        this.methSuppliperData('PUT', DATA)
        this.getSuppliperList()
      }
      this.updataTableData()
      this.dialogFonfig.isShowDialogVisible = false
      this.openForm = this.$options.data().openForm;
    },
    // 取消提交数据操作
    clickCancelData () {
      this.dialogFonfig.isShowDialogVisible = false
      // 用于当前 Vue 实例的初始化选项
      this.openForm = this.$options.data().openForm
      //审核对话框
      this.dialogFonfigAudit.isShowDialogVisible = false
      this.auditName = this.$options.data().auditName
    },

    // 编辑操作
    async clickEditData (idx, row) {
      console.log(row.ID)
      const data = {
        id: row.ID
      }
      await getSuppliperListDetail(data).then((res) => {
        const DATA = res.data
        if (DATA.code == 200) {
          this.$message({
            message: DATA.message,
            type: 'success'
          })
          console.log(DATA)
        }
        this.openForm = {
          abbreviation: DATA.data.abbreviation,
          address: DATA.data.address,
          city_id: DATA.data.city_id,
          county_id: DATA.data.county_id,
          entry_date: DATA.data.entry_date,
          goods_category_id: DATA.data.goods_category_id,
          id: DATA.data.id,
          license_pic: DATA.data.license_pic,
          name: DATA.data.name,
          nickname: DATA.data.nickname,
          operation_center_id: DATA.data.operation_center_id,
          phone: DATA.data.phone,
          province_id: DATA.data.province_id,
          remarks: DATA.data.remarks,
          telephone: DATA.data.telephone,
          tour_area_id: DATA.data.tour_area_id,
          town_id: 0,
          type: DATA.data.type,
          username: DATA.data.username,
          area: [DATA.data.province_id,
          DATA.data.city_name,
          DATA.data.county_id,
          ],
        }
      })


      this.dialogFonfig.isShowDialogVisible = true
      this.dialogFonfig.openType = 'edit'
    },
    // 删除操作
    clickDeleteData (idx, row) {
      const DATA = {
        id: row.ID
      }
      console.log(row.id)
      this.methSuppliperData('DELETE', DATA)
      this.updataTableData()
    },
    //审核操作
    clickAuditData (inx, row) {
      // ----------------数据格式-----------------
      // id	是	int	ID
      // status	是	int	状态 3、通过 2、驳回
      // remarks	是	string	审核理由
      console.log(row)
      this.auditForm = {
        id: row.ID,
        name: row.nickname,
        status: row.status,
      }
      this.dialogFonfigAudit.isShowDialogVisible = true;
    },
    //审核结果提交
    async clickSubmitAuditData (statue, data) {
      // ----------------数据格式-----------------
      //id	是	int	ID
      // status	是	int	状态 3、通过 2、驳回
      const DATA = {
        id: data.id,
        status: statue,
        remarks: data.remarks
      }
      await this.AuditSupplier(DATA).then(async (res) => {
        if (res.code == 200) {
          await this.updataTableData();
        }
      });//调用审核接口  

      this.dialogFonfigAudit.isShowDialogVisible = false
    },
    //刷新表格
    updataTableData () {
      this.getSuppliperList()
    },

  }
}
</script>

<style lang="scss" scoped>
#Manage {
  display: flex;
  display: -webkit-flex;
  // 主轴方向
  flex-direction: column;
  height: 100%;
  .el-transfer {
    text-align: center;
    /deep/ .el-transfer-panel {
      text-align: left;
    }
  }
  .filtrateInput {
    background: #f8f7f7;
    padding: 12px 8px;
    padding-right: 45%;
    margin: 8px 0px;
  }
  span {
    font-size: 14 px;
    margin-left: 8px;
    color: #0486fe;
    cursor: pointer;
  }
  /deep/.el-dialog {
    width: 700px;
  }
}
</style>
